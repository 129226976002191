import store from '@/store';

//将时间戳转换为Date对象
export function timestampToYYYYMMDD(timestamp) {
  const date = new Date(timestamp * 1000);
  const year = date.getFullYear();
  const month = ("0" + (date.getMonth() + 1)).slice(-2); // 月份需要加1，且保证两位数显示
  const day = ("0" + date.getDate()).slice(-2); // 保证日期显示两位数
  return year + '-' + month + '-' + day;
}


/**
 * 树形数据转换
 * @param {*} data
 * @param {*} id
 * @param {*} pid
 */
export function treeDataTranslate(data, id, pid = 'parentId', depth) {
  let res = []
  let temp = {}
  if (!data)
    return
  for (var i = 0; i < data.length; i++) {
    temp[data[i][id]] = data[i]
  }
  for (var k = 0; k < data.length; k++) {
    if (temp[data[k][pid]] && data[k][id] !== data[k][pid]) {
      if (!temp[data[k][pid]]['children']) {
        temp[data[k][pid]]['children'] = []
      }
      // 如果传入了层级，则把当前层级作为父级，依次递增
      if (depth != undefined) {
        if (k === 1) {
          temp[data[k][pid]]['depth'] = depth + 1;
        } else {
          temp[data[k][pid]]['depth'] = temp[data[k - 1][pid]].depth + 1
        }
        data[k]['depth'] = temp[data[k][pid]].depth + 1
      }
      temp[data[k][pid]]['children'].push(data[k]);
    } else {
      res.push(data[k])
    }
  }
  return res
}


export function getRole() {
  const roles = ["admin", "common"];
  const storeRoles = store.state.user.roles;
  if (storeRoles) {
    if (roles.indexOf(storeRoles[0]) > -1) {
      return true;
    } else {
      return false;
    }
  }
}