<template>
  <div class="dialog">对话框组件</div>
</template>
<script>
import dialog from "./dialog.js";

export default dialog;
</script>
<style scoped rel="stylesheet/stylus">
@import "~@/assets/style/common.scss";
@import "dialog.scss";
</style>