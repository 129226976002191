/* eslint-disable no-shadow */
import { login, loginSms, logout,getInfo } from '@/api/login';
import {
  getToken,
  setToken,
  removeToken,
} from '@/utils/auth';

const state = {
  token: getToken(),
  name: '',
  deptName: '',
  avatar: '',
  roles: [],
  userInfo: {},
  permissions: [],
};

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token;
  },
  SET_USER_INFO: (state, userInfo) => {
    state.userInfo = userInfo;
  },
  SET_NAME: (state, name) => {
    state.name = name;
  },
  SET_DEPT_NAME: (state, deptName) => {
    state.deptName = deptName;
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar;
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles;
  },
  SET_PERMISSIONS: (state, permissions) => {
    state.permissions = permissions;
  },
};

const actions = {
  login({ commit }, params) {
    return new Promise((resolve, reject) => {
      login(params).then((response) => {
        const { data } = response;
        commit('SET_TOKEN', data.access_token);
        setToken(data.access_token);
        // setOaToken(data.x_token);
        resolve();
      }).catch((error) => {
        reject(error);
      });
    });
  },

  loginSms({ commit }, params) {
    return new Promise((resolve, reject) => {
      loginSms(params).then((response) => {
        const { data } = response;
        commit('SET_TOKEN', data.access_token);
        setToken(data.access_token);
        // setOaToken(data.x_token);
        resolve();
      }).catch((error) => {
        reject(error);
      });
    });
  },

  getInfo({ commit }) {
    return new Promise((resolve, reject) => {
      getInfo().then((response) => {
        const { user } = response;
        commit('SET_USER_INFO', user);
        commit('SET_NAME', user.nickName);
        commit('SET_ROLES', response.roles);
        return response;
      }).then(async (response) => {
        const { user } = response;
        resolve(response);
      }).catch((error) => {
        reject(error);
      });
    });
  },

  logout({ commit }) {
    return new Promise((resolve, reject) => {
      logout().then(() => {
        commit('SET_TOKEN', '');
        commit('SET_ROLES', []);
        commit('SET_PERMISSIONS', []);
        removeToken();
        resolve();
      }).catch((error) => {
        reject(error);
      });
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
