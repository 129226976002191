<template>
  <div class="data-con">
    <div class="left">
      <div class="stick-nav-logo">
        <a href="/" style="width: 144px"> </a>
      </div>
      <el-menu
        :default-active="activeIndex"
        :unique-opened="true"
        :collapse-transition="false"
        mode="vertical"
      >
      <el-menu-item index="5" @click="openPage('/materialManagement', 1)">
          <i class="el-icon-s-marketing"></i>
          <span slot="title">素材管理</span>
        </el-menu-item>
        <div v-for="sunItem in menuList" :key="sunItem.index">
          <el-submenu :index="sunItem.index">
            <template slot="title">
              <i :class="sunItem.icon"></i>
              <span>{{ sunItem.name }}</span>
            </template>
            <el-menu-item-group>
              <el-menu-item
                v-for="(itemChi, indexChi) in sunItem.children"
                :key="indexChi"
                :index="itemChi.index"
                :base-path="sunItem.route"
                @click="openPage(itemChi.route, itemChi.index)"
                >{{ itemChi.name }}</el-menu-item
              >
            </el-menu-item-group>
          </el-submenu>
        </div>
        <el-menu-item index="4" @click="openPage('/newsManegment', 4)">
          <i class="el-icon-chat-line-round"></i>
          <span slot="title">新闻管理</span>
        </el-menu-item>
        <el-menu-item index="5" @click="openPage('/customManagment', 5)">
          <i class="el-icon-user"></i>
          <span slot="title">客户用户管理</span>
        </el-menu-item>
      </el-menu>
    </div>
    <div class="right">
      <router-view />
    </div>
  </div>
</template>
<script>
// import DialogboxCom from "@/components/dialogboxCom/dialogboxCom.vue";
export default {
  components: {},
  data() {
    return {
      isCollapse: false,
      menuList: [
        // {
        //   icon: "el-icon-s-marketing",
        //   name: "素材管理",
        //   index: "1",
        //   route: "/materialManagement"
        // },
        {
          icon: "el-icon-s-unfold",
          name: "菜单管理",
          route: "/menuSet",
          index: "2",
          children: [
            {
              name: "中文菜单",
              route: "/menuSet?lang=0",
              index: "2-1",
            },
            {
              name: "英文菜单",
              route: "/menuSet?lang=1",
              index: "2-2",
            },
          ],
        },
        {
          icon: "el-icon-bank-card",
          name: "解决方案管理",
          route: "/solutionManagment",
          index: "3",
          children: [
            {
              name: "中文解决方案",
              route: "/solutionManagment?lang=0",
              index: "3-1",
            },
            {
              name: "英文解决方案",
              route: "/solutionManagment?lang=1",
              index: "3-2",
            },
          ],
        },
      ],
      activeIndex: "1",
    };
  },

  created() {
    // this.activeIndex = this.menuList.find(
    //   (item) => item.route === this.$route.path
    // ).index;
  },
  methods: {
    openPage(route, index) {
      this.activeIndex = index;
      this.$router.push(route);
    },
  },
};
</script>
<style scoped lang="scss">
.stick-nav-logo a {
  display: inline-block;
  width: 100px;
  height: 52px;
  background: url(./img/LOGOB.svg) no-repeat 0px 17px !important;
  background-size: 100%;
}

/deep/.el-menu {
  // height: 100vh;
  border-right: unset !important;
  list-style: none;
  position: unset !important;
  .el-menu-item {
    font-size: 16px !important;
  }
}
.data-con {
  height: 100vh;
  display: flex;
  .left {
    width: 210px;
    box-shadow: -1px 2px 4px 2px rgba(177, 175, 175, 0.8);
  }
  .right {
    width: 100%;
    padding: 12px 16px;
  }
}
</style>
