/* eslint-disable */
/**
* 参数处理
* @param {*} params  参数
*/
export function tansParams(params) {
  let result = '';
  for (const propName of Object.keys(params)) {
    const value = params[propName];
    const part = `${encodeURIComponent(propName)}=`;
    if (value !== null && value !== '' && typeof (value) !== 'undefined') {
      if (typeof value === 'object') {
        for (const key of Object.keys(value)) {
          if (value[key] !== null && value[key] !== '' && typeof (value[key]) !== 'undefined') {
            const params = `${propName}[${key}]`;
            const subPart = `${encodeURIComponent(params)}=`;
            result += `${subPart + encodeURIComponent(value[key])}&`;
          }
        }
      } else {
        result += `${part + encodeURIComponent(value)}&`;
      }
    }
  }
  return result;
}

// 验证是否为blob格式
export async function blobValidate(data) {
  try {
    const text = await data.text();
    JSON.parse(text);
    return false;
  } catch (error) {
    return true;
  }
}

// 验证url是否为当前origin下
export function isCurrentOrigin(url) {
  const pathArray = url.split('/');
  const pathOrigin = pathArray[0] + '//' + pathArray[2];
  if(pathOrigin === window.location.origin) {
    return true;
  }
  return false;
}

// 验证手机号格式
export function isPhone(phone) {
  const reg = /^[1][3,4,5,7,8][0-9]{9}$/;
  if (!reg.test(phone)) {
    return false;
  } else {
    return true;
  }
}
