import request from '@/utils/request';

// 发送验证码
export function sendSms(phone) {
  return request({
    url: `/auth/sendSms?phone=${phone}`,
    method: 'get',
  });
}

// 登录
export function login(data) {
  return request({
    url: `/auth/login`,
    method: 'post',
    data
  });
}

// 验证码登录
export function loginSms(data) {
  return request({
    url: '/auth/sms',
    headers: {
      isToken: false,
    },
    method: 'post',
    data,
  });
}


// 注册新增用户
export function registerUser(data) {
  return request({
    url: `/system/user`,
    method: 'post',
    data
  });
}

// 获取用户信息
export function getInfo(token) {
  return request({
    headers: {
      'Authorization': token,
    },
    url: '/system/user/getInfo',
    method: 'get',
  });
}

export function logout() {
  return request({
    url: '/auth/logout',
    method: 'delete',
  });
}
