<template>
  <div>
    <div
      class="headerp"
      :style="{
        position:
          routName != 'Home' || !$store.state.pageset.pcshow
            ? 'fixed'
            : 'relative',
      }"
    >
      <!-- 最新推送消息 -->
      <!-- <div class="heafer-new-msg">
        <div class="heafer-new-msg-con">七腾机器人全国统一免费服务热线:400-8765-700</div>
      </div> -->
      <div class="header">
        <div v-if="$store.state.pageset.pcshow" class="headerVis">
          <div
            class="stick-nav"
            :class="$store.state.pageset.menuShow ? 'header-active ' : ''"
          >
            <ul>
              <!-- 注册页面的pc端，logo为黑色 -->
              <div
                v-if="$store.getters.change === 'zh'"
                class="stick-nav-logo zh-stick-nav-logo"
                :class="isBlackLogo ? 'zh-black-logo' : ''"
              >
                <a href="/" style="width: 144px"> </a>
              </div>
              <div
                v-else
                class="stick-nav-logo en-stick-nav-logo"
                :class="isBlackLogo ? 'en-black-logo' : ''"
              >
                <a :href="$store.getters.zh ? '/' : '/en'" style="width: 144px">
                </a>
              </div>
            </ul>
            <!-- 登录注册页面不显示菜单 -->
            <el-menu
              class="el-menu-demo"
              mode="horizontal"
              active-text-color="#171A20"
              :default-openeds="openeds"
              v-if="routName != 'login' && routName != 'register'"
            >
              <div
                class="el-submenu-drop"
                v-for="(menuItem, index) in menuList"
                :key="index"
              >
                <el-submenu
                  v-if="menuItem.children"
                  index="1"
                  popper-class="popper"
                  style="box-shadow: unset !important"
                  @mouseenter.native="handleOpen('enter', menuItem)"
                  @click.native="solutiondetails(menuItem)"
                >
                  <template slot="title">{{ menuItem.pageName }}</template>
                </el-submenu>
                <el-menu-item v-else @click="solutiondetails(menuItem)">
                  {{ menuItem.pageName }}</el-menu-item
                >
              </div>
              <!-- 只有登录了后台管理角色才可见 -->
              <el-menu-item v-if="userInfo && userAdmin" @click="openDataSet"
                >数据管理</el-menu-item
              >
            </el-menu>
            <ul
              class="stick-nav-opt"
              v-if="routName != 'login' && routName != 'register'"
            >
              <!-- 语言切换 -->
              <div class="dropdown">
                <li class="dropbtn" @click.prevent="showEnglish = !showEnglish">
                  <span>中文</span>
                  <span>|</span>
                  <span>English</span>
                </li>
              </div>
              <!-- <lang /> -->
              <el-dropdown v-if="userInfo.userName">
                <li class="user-name">{{ userInfo.nickName }}</li>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item @click.native="logout"
                    >退出登录</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
              <li v-else @click="openLogin" class="login-logo">
                <i class="el-icon-s-custom"></i>
              </li>
              <!-- 只有登录了后台管理角色才可见 -->
              <li
                @click="changeSet('open')"
                v-if="!$store.state.pageset.isSetting && userAdmin"
              >
                <el-button size="mini" type="primary">设置</el-button>
              </li>
              <li
                @click="changeSet('close')"
                v-else-if="$store.state.pageset.isSetting && userAdmin"
                style="color: black"
              >
                <el-button size="mini" type="success"
                  >收叠<i class="el-icon-arrow-right"></i
                ></el-button>
              </li>
            </ul>
            <ul class="stick-nav-opt" v-else>
              <!-- 语言切换 -->
              <!-- <lang /> -->
              <div class="dropdown">
                <li class="dropbtn" @click.prevent="showEnglish = !showEnglish">
                  <span>中文</span>
                  <span>|</span>
                  <span>English</span>
                </li>
              </div>
            </ul>
          </div>
        </div>
        <div v-else class="stick-nav stick-nav-mobile">
          <ul>
            <div
              :class="$store.getters.change + '-stick-nav-logo stick-nav-logo'"
            >
              <a :href="$store.getters.zh ? '/' : '/en'"></a>
            </div>
          </ul>
          <!-- 语言切换 -->
          <div class="dropdown">
            <div class="dropbtn" @click.prevent="showEnglish = !showEnglish">
              <div>中文</div>
              <div>|</div>
              <div>English</div>
              <!-- {{ $store.getters.change === "zh" ? "中文（简体）" : "English" }}
              <i v-if="!showEnglish" class="el-icon-caret-right"></i>
              <i v-else class="el-icon-caret-top"></i> -->
            </div>
          </div>
          <ul>
            <li @click="menuDialogVisible = true">
              {{ $store.getters.zh ? "导航栏" : "Navigation bar" }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <lang
      :showEnglishMobile="showEnglish"
      @closeEnglishMobile="closeEnglishMobile"
    />
    <!-- 菜单遮罩 -->
    <el-drawer
      :append-to-body="false"
      :visible.sync="$store.state.pageset.menuShow"
      direction="ttb"
      size="380px"
      :show-close="false"
      style="top: 50px"
      custom-class="menu-dia"
      :modal="false"
      @close="$store.state.pageset.menuShow = false"
    >
      <div class="header-active">
        <div class="menu-product">
          <div
            :class="
              nowMenu.path === '/solution'
                ? 'menu-product-left-solution'
                : 'menu-product-left'
            "
          >
            <div
              v-for="(proItem, index) in menuChildList"
              :key="index"
              class="menu-product-item"
              @click="solutiondetails(proItem)"
            >
              <div v-if="nowMenu.path != ''">
                <div>
                  <img :src="proItem.cover" height="167px" width="167px" />
                </div>
                <p class="menu-product-name">{{ proItem.pageName }}</p>
                <div class="menu-product-order" v-if="$store.getters.zh">
                  <a @click="solutiondetails(proItem)">了解</a>
                  <a  @click.stop="openReservation()" class="menu-product-order-a">订购</a>
                </div>
                <div class="menu-product-order" v-else>
                  <a @click="solutiondetails(proItem)">Learn more</a>
                  <a  @click.stop="openReservation()"  class="menu-product-order-a"
                    >order</a
                  >
                </div>
              </div>
              <div v-else>
                <div class="menu-product-item">
                  <p class="menu-find-name">{{ proItem.pageName }}</p>
                  <p
                    class="menu-find-dec"
                    v-for="(grandson, sIndex) in proItem.children"
                    :key="sIndex"
                    @click.stop="solutiondetails(grandson)"
                  >
                    {{ grandson.pageName }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <!-- 解决方案特殊样式 -->
          <div
            class="menu-solution-line"
            v-if="nowMenu.path === '/solution'"
          ></div>
          <div v-if="nowMenu.path === '/solution'" class="menu-solution-ui">
            <p
              class="menu-product-name"
              v-for="(proItem, index) in menuChildList"
              :key="index"
              @click="solutiondetails(proItem)"
            >
              {{ proItem.pageName }}
            </p>
            <p
              class="menu-product-name"
              @click="solutiondetails({ path: '/ptzSolution' })"
            >
              {{
                $store.getters.change === "zh"
                  ? "SAAS系统服务方案"
                  : "SAAS system service solution"
              }}
            </p>
            <p class="menu-product-name">......</p>
          </div>
        </div>
      </div>
    </el-drawer>
    <!-- 手机菜单遮罩 -->
    <el-drawer
      :visible.sync="menuDialogVisible"
      direction="rtl"
      style="top"
      size="100%"
      @close="menuDialogVisible = false"
    >
      <div class="menu">
        <ul>
          <li
            v-for="(menuItem, index) in menuList"
            :key="index"
            :title="menuItem.pageName"
            @click="openProduct(menuItem)"
            class="menu-li"
          >
            <span> {{ menuItem.pageName }}</span>
            <!-- 有下级菜单的才有箭头 -->
            <i v-if="menuItem.children" class="el-icon-arrow-right"></i>
          </li>
          <li v-if="userInfo.userName" @click="logout" class="login-logo">
            {{ $store.getters.change === "zh" ? "退出登录" : "logout" }}
          </li>
          <li v-else @click="openLogin" class="login-logo">
            {{ $store.getters.change === "zh" ? "登录" : "login" }}
          </li>
        </ul>
      </div>
      <!-- 产品 -->
      <el-drawer
        :title="$store.getters.change === 'zh' ? '产品' : 'Products'"
        :append-to-body="true"
        :visible.sync="proDia"
        direction="rtl"
        size="100%"
        @close="proDia = false"
      >
        <i class="i-left" @click="proDia = false">
          <img src="./img/left.svg" height="15px" width="15px"
        /></i>
        <ul>
          <li
            v-for="(proItem, index) in menuChildList"
            :key="index"
            :title="proItem.name"
            @click="solutiondetails(proItem)"
            class="pro-li"
          >
            <!-- 如果没有跳转路径链接。纯文字 -->
            <div v-if="proItem.path === ''">
              <!-- <p class="menu-find-name" @click="solutiondetails(proItem.path)">
                {{ proItem.pageName }}
              </p> -->
              <p
                class="menu-find-name"
                v-for="(grandson, sIndex) in proItem.children"
                :key="sIndex"
                @click.stop="solutiondetails(grandson)"
              >
                {{ grandson.pageName }}
              </p>
            </div>

            <div v-else>
              <img :src="proItem.cover" height="100px" width="100px" />
            </div>
            <div class="menu-product-dec" v-if="proItem.path != ''">
              <p class="menu-product-name">{{ proItem.pageName }}</p>
              <div class="menu-product-order" v-if="$store.getters.zh">
                <a @click="solutiondetails(proItem.path)">了解</a
                >&nbsp;&nbsp;&nbsp;&nbsp;
                <a @click="openReservation()" > 订购</a>
              </div>
              <div class="menu-product-order" v-else>
                <a @click="solutiondetails(proItem.path)">Learn more</a
                >&nbsp;&nbsp;&nbsp;&nbsp;
                <a @click="openReservation()" >order</a>
              </div>
            </div>
          </li>
          <div
            class="menu-solution-line"
            v-if="nowMenu.path === '/solution'"
          ></div>
          <div class="menu-solution-ui" v-if="nowMenu.path === '/solution'">
            <p
              class="menu-product-name"
              v-for="(so, index) in menuChildList"
              :key="index"
              @click="solutiondetails(so)"
            >
              {{ so.pageName }}
            </p>
            <p
              class="menu-product-name"
              @click="solutiondetails({ path: '/ptzSolution' })"
            >
              {{
                $store.getters.change === "zh"
                  ? "SAAS系统服务方案"
                  : "SAAS system service solution"
              }}
            </p>
          </div>
        </ul>
      </el-drawer>
    </el-drawer>
  </div>
</template>

<script>
import header from "./header.js";
export default header;
</script>

<style lang="scss" scoped>
@import "./header.scss";
</style>
