import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import animated from 'animate.css';
import infiniteScroll from 'vue-infinite-scroll';
import 'lib-flexible';
Vue.use(infiniteScroll)
Vue.use(animated)
Vue.use(ElementUI);
Vue.config.productionTip = false

// 全局滚动到可视区域后增加样式
Vue.directive('animate', {
    inserted: function (el, binding) {
        // 聚焦元素
        binding.addClass = () => {
            const { top } = el.getBoundingClientRect()
            const h = document.documentElement.clientHeight || document.body.clientHeight
            if (top < h) {
                if (el.className.indexOf(binding.value) == -1) {
                    // 下面单引号中间是一个空格
                    el.className = binding.value + ' ' + el.className
                }
                if (binding.addClass) {
                    window.removeEventListener('scroll', binding.addClass)
                }
            }
        }
        window.addEventListener('scroll', binding.addClass, true)
        binding.addClass()
    },
    unbind: function (el, binding) {
        if (binding.addClass) {
            window.removeEventListener('scroll', binding.addClass)
        }
    }
})
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
