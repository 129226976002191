import CommonDialog from "@/components/dialog/dialog.vue"
import Header from "@/components/header/header.vue"
import Footer from "@/components/footer/footer.vue"
import HomeContent from "./components/content/content.vue"
export default {
    name: 'Home',
    data() {
        return {
            windowWidth: document.documentElement.clientWidth, //实时屏幕宽度
        }
    },
    mounted() {
        // 如果不是电脑端，
        if (this.windowWidth <= 750) {
            this.$store.dispatch("pageset/getpcshow", false);
        } else  {
            this.$store.dispatch("pageset/getpcshow", true);
        }
    },
    components: {
        CommonDialog,
        Header,
        HomeContent,
        Footer
    },
    watch: {
    },
}