import request from '@/utils/request';

// 获取分区
export function getWebControlMaterialArea(params) {
    return request({
        url: '/control/WebControlMaterialArea/list',
        method: 'get',
        params,
    });
}

// 新增分区
export function addWebControlMaterialArea(data) {
    return request({
        url: '/control/WebControlMaterialArea',
        method: 'post',
        data
    });
}

// 修改分区
export function editWebControlMaterialArea(data) {
    return request({
        url: '/control/WebControlMaterialArea',
        method: 'put',
        data
    });
}

// 删除分区
export function deleteWebControlMaterialArea(areaIds) {
    return request({
        url: `/control/WebControlMaterialArea/${areaIds}`,
        method: 'delete',
    });
}

// 获取元素
export function getElementList(data) {
    return request({
        url: `/control/WebControlElement/list`,
        method: 'post',
        data
    });
}

// 新增元素
export function addElement(data) {
    return request({
        url: '/control/WebControlElement',
        method: 'post',
        data
    });
}

// 修改元素
export function editElement(data) {
    return request({
        url: '/control/WebControlElement',
        method: 'put',
        data
    });
}

// 删除元素
export function deleteElementById(elementIds) {
    return request({
        url: `/control/WebControlElement/${elementIds}`,
        method: 'delete',
    });
}