
import {
    getSubInfo, //获取页面替换功能表详细信息
  } from "@/api/pageSet/pageSet";

const state = {
    bannerIndex: 0,
    activeIndex: '1',//数据设置菜单栏索引
    changetitle: '中文',
    change: "zh",
    pcshow: true,
    zh: true,
    linkZhIndex: 0,
    linkEnIndex: 0,
    isSetting: false,//设置状态
    sourceData:{},//设置数据
    pageId:null,//页面Id
    menuShow:false,//菜单显示
};

const mutations = {
    SET_ACTIVE_INDEX(state, newactive) {
        state.activeIndex = newactive
    },
    SET_BANNER_INDEX(state, newactive) {
        state.bannerIndex = newactive
    },
    SET_CHANGE_TITLE(state, newchangetitle) {
        state.changetitle = newchangetitle
    },
    SET_CHANGE(state, newchange) {
        state.change = newchange
    },
    SET_PC_SHOW(state, newpcshow) {
        state.pcshow = newpcshow
    },
    SET_ZH(state, newzh) {
        state.zh = newzh
    },
    getlinkZhIndex(state, newdata) {
        state.linkZhIndex = newdata
    },
    getlinkEnIndex(state, newdata) {
        state.linkEnIndex = newdata
    },
    SET_SETTING(state, newdata) {
        debugger
        state.isSetting = newdata;
    },
    SET_SOURCE_DATA(state, newdata) {
        state.sourceData = newdata;
    },
    SET_MENU_SHOW(state, newdata) {
        state.menuShow = newdata;
    },
};

const actions = {
    getactiveIndex({ commit }, activeIndex) {
        commit('SET_ACTIVE_INDEX', activeIndex);
    },
    getchangetitle({ commit }, activeIndex) {
        commit('SET_CHANGE_TITLE', activeIndex);
    },
    getchange({ commit }, change) {
        commit('SET_CHANGE', change);
    },
    getpcshow({ commit }, pcshow) {
        commit('SET_PC_SHOW', pcshow);
    },
    getzh({ commit }, zh) {
        commit('SET_ZH', zh);
    },
    getSourceData({ commit }, sourceData) {
        commit('SET_SOURCE_DATA', sourceData);
    },
    getIsSetting({ commit }, isSetting) {
        commit('SET_SETTING', isSetting);
    },
    getbannerIndex({ commit }, bannerIndex){
        commit('SET_BANNER_INDEX', bannerIndex);
    },
    
    getMenuShow({ commit }, menuShow) {
        commit('SET_MENU_SHOW', menuShow);
    },
    // 获取配置信息，并写入缓存
    getPageInfo({ commit },pageId) {
        //  state.change
        return new Promise((resolve, reject) => {
          getSubInfo( pageId).then((response) => {
            commit('SET_SOURCE_DATA', response.data? response.data:[]);
            return response;
          }).then(async (response) => {
            resolve(response);
          }).catch((error) => {
            reject(error);
          });
        });
      },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
