<template>
  <!-- 当后台管理用户登录时的模板 -->
  <div v-if="userAdmin && $store.state.pageset.pcshow">
    <Header></Header>
    <div class="wrapper">
      <div
        id="wrapperCon"
        :class="
          $store.state.pageset.isSetting ? 'wrapper-con' : 'wrapper-con-fill'
        "
      >
        <router-view v-if="routerAlive"></router-view>
        <div v-if="$route.name != 'Home'">
          <Footer v-if="$store.state.pageset.zh" />
          <FooterEN v-else />
        </div>
      </div>
      <!-- 右侧设置栏 -->
      <div v-if="$store.state.pageset.isSetting" class="sidebar">
        <Sidebar class="sidebar-con" />
      </div>
    </div>
  </div>
  <div v-else>
    <Header></Header>
    <router-view v-if="routerAlive"></router-view>
    <div v-if="$route.name != 'Home'">
      <Footer v-if="$store.state.pageset.zh" />
      <FooterEN v-else />
    </div>
  </div>
</template>
<script>
import Sidebar from "./components/Sidebar/index.vue";
import Header from "@/components/header/header.vue";
import Footer from "@/components/footer/footer.vue";
import FooterEN from "@/components/footer/en/footer_en.vue";
import { getRole } from "@/utils/common.js";
export default {
  components: {
    // DialogboxCom,
    Sidebar,
    Header,
    Footer,
    FooterEN,
  },
  data() {
    return {
      userAdmin: false,
      routerAlive: true,
    };
  },
  created() {
    this.loadDefaultValue()
  },
  watch: {
    "$store.state.pageset.isSetting": {
      // 设置的时候，保持滚动条位置一致
      handler: function (newVal) {
        let sTop = document.documentElement.scrollTop;
        if (newVal) {
          setTimeout(() => {
            document.getElementById("wrapperCon").scrollTop = sTop;
          }, 10);
        } else {
          let wTop = document.getElementById("wrapperCon").scrollTop;
          setTimeout(() => {
            document.documentElement.scrollTop = wTop;
          }, 100);
        }
      },
    },
    $route: function () {this.loadDefaultValue()},
  },
  methods: {
   loadDefaultValue() {
      this.userInfo = this.$store.state.user.userInfo;
      this.routerAlive = false;
      this.$nextTick(() => {
        // 管理员和普通角色能配置数据
        this.userAdmin = getRole();
        this.routerAlive = true;
      });
    },
  },
};
</script>
<style scoped lang="scss">
.wrapper {
  display: flex;
  justify-content: space-between;

  .wrapper-con-fill {
    width: 100%;
  }

  .wrapper-con {
    width: 70%;
    height: 100vh;
    overflow-x: hidden;
  }

  .sidebar {
    width: 30%;
    height: 100vh;
    box-shadow: inset 0px 0px 2px 2px rgba(0, 0, 0, 0.5);
    overflow: auto;
    .sidebar-con {
      padding-top: 40px;
      overflow: auto;
    }
  }
}
</style>
