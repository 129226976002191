<template>
  <div class="change">
    <!-- 语言切换 -->
    <!-- <div
      :class="
        $route.name === 'login' || $route.name === 'register' || showEnglish
          ? 'dropdown dropdown_login'
          : 'dropdown'
      "
      v-if="$store.state.pageset.pcshow"
    >
      <div class="dropbtn" @click="showEnglish = !showEnglish">
        {{ $store.getters.zh ? "中文（简体）" : "English" }}
        <i v-if="!showEnglish" class="el-icon-caret-right"></i>
        <i v-else class="el-icon-caret-top"></i>
      </div>
      <div class="dropdown-content" v-if="showEnglish">
        <div
          class="dropdown-content-item"
          @click="langChange($store.getters.zh ? 'en' : 'zh')"
        >
          {{ $store.getters.zh ? "English" : "中文（简体）" }}
        </div>
      </div>
    </div> -->
    <!-- <div
      class="dropdown-content-moblie"
      v-if="showEnglishMobile && !$store.state.pageset.pcshow"
    >
      <div class="dropdown-content-item" @click="langChange('zh')">
        中文（简体）
      </div>
      <div class="dropdown-content-item" @click="langChange('en')">English</div>
    </div> -->
  </div>
</template>

<script>
export default {
  props: {
    showEnglishMobile: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showEnglish: false, //语言切换显示
    };
  },
  watch: {
    showEnglishMobile(val) {
      this.langChange(val?'en':'zh');
    },
  },
  methods: {
    // 语言切换
    langChange(type) {
      let path = this.$route.fullPath;
      let lang = type ? type : this.$store.getters.change;
      if (lang === "zh") {
        this.$store.dispatch("pageset/getzh", true);
        //根据"/"分割路由  跳转中文路由
        let patharry = this.$route.fullPath.split("/");
        if (patharry[1] === "en") {
          patharry.splice(1, 1);
          path = patharry.join("/");
          //解决中英文切换报错的问题
          if (path === "") {
            path = "/";
          }
        }
        this.$store.dispatch("pageset/getchangetitle", "中文");
        this.$store.dispatch("pageset/getchange", "zh");
        if (this.$route.name === "login") {
          this.$router.push({
            path,
          });
        } else {
          this.$router.push(lang === "zh" ? "/" : "/en");
        }
        this.$emit("closeEnglishMobile");
      } else {
        this.$store.dispatch("pageset/getzh", false);
        //跳转英文路由
        this.$store.dispatch("pageset/getchangetitle", "English");
        this.$store.dispatch("pageset/getchange", "en");
        path = `/en${path}`;
        if (this.$route.name === "login") {
          this.$router.push({
            path,
          });
        } else {
          this.$router.push(lang === "zh" ? "/" : "/en");
        }
      }
      // this.showEnglish = !this.showEnglish;
      // this.$emit("closeEnglishMobile");
    },
  },
};
</script>
<style lang="scss" scoped>
/* 容器 <div> - 需要定位下拉内容 */
// 设置颜色变量
.dropdown {
  font-family: Microsoft YaHei;
  position: relative;
  display: inline-block;
  cursor: pointer;
  font-size: 14px;

  /* 下拉按钮样式 */
  .dropbtn {
    padding: 4px 5px;
    color: var(--var-primary-color);
    border: none;
    cursor: pointer;
    width: 103px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  /* 下拉内容 (默认隐藏) */
  .dropdown-content {
    width: 103px;
    position: absolute;
    background-color: black;
    padding: 4px 5px 9px 5px;
    color: white;

    .dropdown-content-item {
      padding-top: 5px;
      text-align: center;
      border-top: 1px solid #ffffff;
    }
  }
}

// 登录注册页面切换语言
.dropdown_login {
  background-color: black;
  .dropbtn {
    padding: 4px 5px;
    color: white;
  }
}

/* 下拉内容 (默认隐藏) */
.dropdown-content-moblie {
  position: relative;
  width: 100vw;
  position: fixed;
  background-color: black;
  color: white;

  .dropdown-content-header {
    display: flex;
  }

  .dropdown-content-item {
    padding: 5px;
    font-size: 14px;
    margin-left: 20px;
    text-align: left;
  }
}
</style>