import { render, staticRenderFns } from "./PageMain.vue?vue&type=template&id=23c61767&scoped=true&"
var script = {}
import style0 from "./PageMain.vue?vue&type=style&index=0&id=23c61767&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23c61767",
  null
  
)

export default component.exports