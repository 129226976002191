import request from '@/utils/request';

// 获取菜单
export function getMenuList(language) {
    return request({
        url: `/control/WebControlMenu/list?language=${language}`,
        method: 'get',
    });
}

// 新增菜单
export function addMenuList(data) {
    return request({
        url: '/control/WebControlMenu',
        method: 'post',
        data
    });
}

// 修改菜单
export function editMenuList(data) {
    return request({
        url: '/control/WebControlMenu',
        method: 'put',
        data
    });
}

// 删除菜单
export function deleteMenu(pageIds) {
    return request({
        url: `/control/WebControlMenu/${pageIds}`,
        method: 'delete',
    });
}