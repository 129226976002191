<template>
  <div>
    <el-row :gutter="10" class="material-wrapper">
      <el-col :span="10">
        <page-main>
          <div class="main-body-header" slot="header">
            <div>
              <span class="main-title">素材分区</span>
              <el-button
                style="margin-left: 8px"
                size="mini"
                type="primary"
                @click="addPartition(null, 'add', 'part')"
                >新增分区</el-button
              >
            </div>
            <div>
              <el-input
                size="mini"
                class="form-table_inpse"
                placeholder="请输入分区名"
                v-model="areaPar.areaName"
                @keyup.enter.native="getWebControlMaterialArea"
              >
                <el-button
                  slot="append"
                  icon="el-icon-search"
                  @click="getWebControlMaterialArea"
                ></el-button>
              </el-input>
            </div>
          </div>
          <div class="table-box" slot="body">
            <el-table
              border
              class="table-wrape"
              style="font-size: 14px"
              height="400px"
              v-loading="tablePartLoading"
              row-key="areaId"
              :highlight-current-row="true"
              :data="tablePartData"
              :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
              @row-click="handleNodeClick"
            >
              <el-table-column
                label="分区"
                prop="areaName"
                width="120"
                :show-overflow-tooltip="true"
              >
              </el-table-column>
              <el-table-column prop="createBy" label="创建人" width="80">
              </el-table-column>
              <el-table-column
                prop="createTime"
                label="创建时间"
                :show-overflow-tooltip="true"
              >
              </el-table-column>
              <el-table-column prop="remark" label="备注" :show-overflow-tooltip="true">
              </el-table-column>
              <el-table-column label="操作" width="120">
                <template slot-scope="scope">
                  <el-button
                    size="mini"
                    type="text"
                    @click="addPartition(scope.row, 'addChi', 'part')"
                    >新增</el-button
                  >
                  <el-button
                    size="mini"
                    type="text"
                    @click="addPartition(scope.row, 'edit', 'part')"
                    >编辑
                  </el-button>
                  <el-popconfirm
                    :title="'确定删除该' + scope.row.areaName + '分区吗？'"
                    @confirm="deleteElment(scope.row.areaId, 'part')"
                  >
                    <el-button
                      slot="reference"
                      size="mini"
                      style="margin-left: 10px"
                      type="text"
                      >删除</el-button
                    >
                  </el-popconfirm>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </page-main>
      </el-col>
      <el-col :span="14">
        <page-main>
          <div class="main-body-header" slot="header">
            <div class="main-title">
              {{ cilckPartRow.areaName }}区元素
              <el-button size="mini" type="primary" @click="addElment(null, 'add')"
                >新增元素</el-button
              >
            </div>
            <div>
              <el-input
                size="mini"
                class="form-table_inpse"
                placeholder="请输入元素名"
                v-model="elmentPar.elementName"
                @keyup.enter.native="getElementList"
              >
                <el-button
                  slot="append"
                  icon="el-icon-search"
                  @click="getElementList"
                ></el-button>
              </el-input>
            </div>
          </div>
          <div class="table-box" slot="body">
            <el-table
              :data="tableElementData"
              v-loading="tableElementLoading"
              style="width: 100%"
              height="400px"
              class="table-wrape"
            >
              <el-table-column width="30" v-if="meteIsSelection">
                <template slot-scope="scope">
                  <el-radio
                    @change="radioChange"
                    v-model="selectElementId"
                    :label="scope.row"
                  />
                </template>
              </el-table-column>
              <el-table-column label="元素" width="85">
                <template slot-scope="scope">
                  <div class="demo-image__preview" v-if="scope.row.type == 'image'">
                    <el-image
                      :src="scope.row.elements[0].attUrl"
                      :preview-src-list="[scope.row.elements[0].attUrl]"
                    >
                    </el-image>
                  </div>
                  <div
                    class="demo-image__preview"
                    v-else-if="scope.row.type === 'video'"
                    @click="openVedioPreview(scope.row)"
                  >
                    <video
                      autoplay="false"
                      muted
                      preload
                      class="video"
                      :src="scope.row.elements[0].attUrl"
                    ></video>
                  </div>
                  <div
                    class="demo-image__preview"
                    v-else-if="scope.row.type === 'application'"
                  >
                    <a :href="scope.row.elements[0].attUrl">{{
                      scope.row.elementName
                    }}</a>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="elementName"
                label="名称"
                :show-overflow-tooltip="true"
              />
              <el-table-column prop="type" label="类型" width="100">
                <template slot-scope="scope">
                  <span v-if="scope.row.type === 'image'">图片</span>
                  <span v-else-if="scope.row.type === 'video'">视频</span>
                  <span v-else>其他文件</span>
                </template>
              </el-table-column>
              <el-table-column prop="size" label="大小" width="90">
                <template slot-scope="scope">
                  {{ scope.row.size + "MB" }}
                </template>
              </el-table-column>
              <el-table-column prop="createBy" label="上传人" width="80" />
              <el-table-column
                prop="createTime"
                label="上传时间"
                width="90"
                :show-overflow-tooltip="true"
              >
              </el-table-column>
              <el-table-column prop="remark" label="备注" :show-overflow-tooltip="true" />
              <el-table-column label="操作" width="120">
                <template slot-scope="scope">
                  <el-button size="mini" type="text" @click="addElment(scope.row, 'edit')"
                    >编辑
                  </el-button>
                  <!-- <el-button
                    size="mini"
                    type="text"
                    @click.stop="downFile(scope.row)"
                    >下载
                  </el-button> -->
                  <el-popconfirm
                    title="确定删除该元素吗？"
                    @confirm="deleteElment(scope.row.elementId, 'elment')"
                  >
                    <el-button
                      slot="reference"
                      size="mini"
                      type="text"
                      style="margin-left: 10px"
                      >删除</el-button
                    >
                  </el-popconfirm>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </page-main>
      </el-col>
    </el-row>
    <el-dialog :title="dialogPart.titile" :visible.sync="dialogPart.visible" width="50%">
      <el-form
        ref="partForm"
        :model="partForm"
        :rules="partRules"
        label-width="100px"
        class="form"
      >
        <el-form-item label="分区名" prop="areaName">
          <el-input class="form-table_inpse" v-model="partForm.areaName"></el-input>
        </el-form-item>
        <el-form-item label="创建人">
          <el-input class="form-table_inpse" v-model="partForm.createBy"></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input class="form-table_inpse" v-model="partForm.remark"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogPart.visible = false">取 消</el-button>
        <el-button type="primary" @click="submitFrom('partForm')">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 元素新增 -->
    <el-dialog
      :title="dialogElement.titile"
      :visible.sync="dialogElement.visible"
      width="50%"
      top="5vh"
    >
      <el-form
        ref="elmentForm"
        :model="elmentForm"
        label-width="100px"
        class="elmentForm"
      >
        <el-form-item label="元素">
          <el-row :gutter="10">
            <el-col :span="10">
              <div v-if="elmentForm.type === 'image'">
                <img
                  width="220"
                  height="170"
                  v-if="elmentForm.elements[0]"
                  :src="elmentForm.elements[0].attUrl"
                />
              </div>
              <div v-else-if="elmentForm.type === 'video'">
                <video
                  autoplay="false"
                  muted
                  preload
                  class="video"
                  width="220"
                  height="170"
                  ref="video"
                  :src="elmentForm.elements[0].attUrl"
                ></video>
              </div>
              <div v-else-if="elmentForm.type === 'pdf'">
                <a :href="elmentForm.elements[0].attUrl">{{
                  elmentForm.elements[0].attUrl
                }}</a>
              </div>
            </el-col>
            <el-col :span="12">
              <el-upload
                ref="avatarUpload"
                class="upload-demo"
                drag
                accept="image/jpeg,image/png,video/mp4,.pdf"
                :action="uploadUrl"
                :on-success="uploadSuccess"
                :headers="uploadHeaders"
              >
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                <div class="el-upload__tip" slot="tip">
                  只能上传jpg/png/MP4/pdf文件,大小不超过50MB
                </div>
              </el-upload>
            </el-col>
          </el-row>
        </el-form-item>
        <el-row>
          <el-col :span="12">
            <el-form-item label="元素名">
              <el-input
                class="form-table_inpse"
                v-model="elmentForm.elementName"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="元素类型">
              <el-input
                class="form-table_inpse"
                v-model="elmentForm.type"
                :disabled="true"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="大小">
              <el-input
                class="form-table_inpse"
                v-model="elmentForm.size"
                :disabled="true"
              >
                <template slot="append">MB</template></el-input
              >
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="上传人">
              <el-input class="form-table_inpse" v-model="elmentForm.createBy"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item label="备注">
          <el-input class="form-table_inpse" v-model="elmentForm.remark"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogElement.visible = false">取 消</el-button>
        <el-button type="primary" @click="submitFrom('elmentForm')">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 大图预览 -->
    <el-dialog
      v-if="elmentBigVisiable"
      title="大图预览"
      :visible.sync="elmentBigVisiable"
      width="70%"
      top="3vh"
      @close="elmentBigVisiable"
    >
      <div class="big-element">
        <video autoplay="autoplay" loop="loop">
          <source :src="elmentForm.elements[0].attUrl" />
        </video>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import index from "./index.js";

export default index;
</script>

<style scoped lang="scss">
@import "../common.scss";
/deep/.el-upload-dragger {
  width: 220px !important;
  height: 170px;
}
.el-upload__tip {
  margin-top: 0px;
}

.el-table .click-row {
  background: rgb(211, 210, 209);
}

.demo-image__preview {
  width: 80px;
  height: 80px;
}

.big-element {
  height: 500px;
  width: 750px;
  display: flex;
  justify-content: center;
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  video {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
</style>
