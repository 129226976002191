//页面元素设置
import request from '@/utils/request';

// 新增
export function addReplacement(data) {
    return request({
        url: '/control/Replacement',
        method: 'post',
        data
    });
}

// 修改
export function editReplacement(data) {
    return request({
        url: '/control/Replacement',
        method: 'put',
        data
    });
}

// 获取页面替换功能表详细信息
export function getSubInfo(pageId) {
    return request({
        url: `/control/Replacement/getSubInfo/${pageId}`,
        method: 'get'
    });
}

// 获取页面历史版本
export function getHistroy(pageId) {
    return request({
        url: `/control/Replacement/list?delFlag='2'&pageId=${pageId}`,
        method: 'get'
    });
}

// 获取页面替换功能附属详细信息-历史版本
export function getSubOldInfo(id) {
    return request({
        url: `/control/Replacement/getSubOldInfo/${id}`,
        method: 'get'
    });
}