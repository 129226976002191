<template>
  <div class="home">
    <el-row>
      <el-col :span="24">
        <HomeContentEn class="home_content"/>
      </el-col>
    </el-row>
 
  </div>
</template>
<script>
import home from "./home_en.js";

export default home;
</script>
<style scoped rel="stylesheet/stylus" lang=scss>
@import "~@/assets/style/common.scss";
@import "home_en.scss";
</style>