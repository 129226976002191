<template>
  <div id="app" :class="this.$route.name === 'Home' ? 'home-app' : ''">
    <!-- <Header v-if="this.$store.state.zh && !layoutLogo"></Header>
    <HeaderEn v-else-if="!this.$store.state.zh && !layoutLogo" />
    <header-logo v-else-if="this.$store.state.zh && layoutLogo"></header-logo> -->
    <router-view />
  </div>
</template>
<script>
// import Header from "@/components/header/header.vue";
// import HeaderEn from "@/components/header/en/header_en.vue";
export default {
  components: {
    // Header,
    // HeaderEn,
  },
  data() {
    return {
      windowWidth: document.documentElement.clientWidth, //实时屏幕宽度
    };
  },
  created() {
    //监听页面刷新
    window.addEventListener("beforeunload", () => {
      sessionStorage.setItem("state", JSON.stringify(this.$store.state));
    });
    if (this.windowWidth <= 750) {
      this.$store.dispatch("pageset/getpcshow", false);
    } else {
      this.$store.dispatch("pageset/getpcshow", true);
    }
  },

  watch: {
    windowWidth(val) {
      let that = this;
      if (!this.timer) {
        // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
        this.windowWidth = val;
        this.timer = true;
        setTimeout(function () {
          // 打印screenWidth变化的值
          that.timer = false;
        }, 400);
      }
      if (this.windowWidth <= 740) {
        this.$store.dispatch("pageset/getpcshow", false);
      } else if (this.windowWidth > 740) {
        this.$store.dispatch("pageset/getpcshow", true);
      }
      let hrf = window.location.pathname;
    },
    $route: function () {
      this.$forceUpdate();
    },
  },
  mounted() {
    let that = this;
    // <!--把window.onresize事件挂在到mounted函数上-->
    window.addEventListener("resize", () => {
      return (() => {
        window.fullWidth = document.documentElement.clientWidth;
        that.windowWidth = window.fullWidth; // 宽
      })();
    });
    if (this.windowWidth <= 750) {
      this.$store.dispatch("pageset/getpcshow", false);
    }
  },
  methods: {

  },
};
</script>
<style scoped>
@import url("./assets/style/font.scss");
/* 去掉下拉菜单的阴影 */
.el-menu--popup {
  box-shadow: none !important;
}
#app {
  width: 100%;
}
.home-app {
  overflow: hidden;
}

@media screen and (max-width: 1440px) {
  #app {
    width: 100%;
    min-width: 100%;
  }
}

@media screen and (max-width: 750px) {
  #app {
    width: 100%;
    min-width: 100% !important;
  }
}

@media screen and (max-width: 400px) {
  #app {
    width: 100%;
    min-width: 100% !important;
  }
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: rgb(106, 98, 110, 0.5);
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #ededed;
  border-radius: 5px;
}
</style>
