<template>
  <div>
    <el-dialog
      title="历史版本"
      :visible.sync="dialogHistroyVisible"
      width="70%"
      top="3vh"
      @close="cancelSelect"
    >
      <page-main>
        <div class="main-body-header" slot="header">
          <div class="main-title">历史版本列表</div>
        </div>
        <div class="table-box" slot="body">
          <el-table :data="tableData" style="width: 100%; height: 350px">
            <el-table-column width="30">
              <template slot-scope="scope">
                <el-radio v-model="selectHistroytId" :label="scope.row.id" />
              </template>
            </el-table-column>
            <el-table-column prop="updateBy" label="更改人" width="180">
            </el-table-column>
            <el-table-column prop="createTime" label="更改时间"> </el-table-column>
            <el-table-column prop="remark" label="备注"> </el-table-column>
          </el-table>
        </div>
      </page-main>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogHistroyVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitSelect">查 看</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import PageMain from "@/components/pageMain/PageMain.vue";
import {
  getHistroy, //历史版本
} from "@/api/pageSet/pageSet.js"; //历史版本
export default {
  props: {
    dialogHistroyVisible: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    PageMain,
  },
  data() {
    return {
      tableData: [],
      tableDataLoading: false,
      selectHistroytId: null,
    };
  },
  created() {
    this.getHistroy();
  },
  methods: {
    cancelSelect() {
      this.$emit("cancelHistroy");
    },
    submitSelect() {
      if (!this.selectHistroytId) {
        this.$message.error("请选择一条历史版本！");
        return;
      }
      this.$emit("submitSelect", this.selectHistroytId);
    },
    async getHistroy() {
      this.tableDataLoading = true;
      let pageId = this.$route.path === "/" ? 0 : this.$route.query.pageId;
      let res = await getHistroy(pageId);
      if (res.code === 200) {
        this.tableData = res.data;
        this.tableDataLoading = false;
      } else {
        this.tableDataLoading = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.material-wrapper {
  height: 400px;
}

.el-tree {
  height: 350px;
}

//标题
.main-title {
  color: #333333;
  font-size: 14px;
  font-weight: bold;
  font-size: 14px;
  font-family: Microsoft YaHei-Bold, Microsoft YaHei;
}

.main-body-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
</style>
