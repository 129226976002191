<template>
  <div style="height: 100%" v-if="elmentList.length">
    <div
      class="swiper"
      :class="!this.$store.state.pageset.pcshow ? 'swiper-mobile' : ''"
    >
      <div class="mySwiper img-swiper">
        <div
          class="swiper-wrapper swiper-wrapper"
          v-if="this.$store.state.pageset.pcshow"
        >
          <div
            class="swiper-slide"
            v-for="(hnItem, index) in elmentList"
            :key="index"
          >
            <!-- 滑动切换内容 -->
            <video
              autoplay="autoplay"
              loop
              muted
              preload
              class="video"
              type="video/mp4"
              :src="hnItem.pcImg"
              :ref="'video' + hnItem.pcElementId"
            ></video>
            <div class="img-con">
              <div class="title" :class="index ===3 ? 'title-black' : ''">
                <h1>{{ hnItem.descList[0].descName }}</h1>
                <p>{{ hnItem.descList[1].descName }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-wrapper screen-swiper" v-else>
          <div
            class="swiper-slide"
            v-for="(hnItem, index) in elmentList"
            :key="index"
          >
            <!-- 滑动切换内容 -->
            <!-- <div class="video-div" :id="'video' + index"></div> -->
            <video
              autoplay
              muted
              playsinline
              loop
              x5-video-player-type="h5-page"
              x-webkit-airplay="true"
              webkit-playsinline="true"
              x5-video-player-fullscreen="false"
              class="video"
              type="video/mp4"
              :src="hnItem.phoneImg"
              :ref="'video' + hnItem.phoneElementId"
              :id="'video' + index"
            ></video>
            <div class="img-con">
              <div class="title">
                <h1>{{ hnItem.descList[0].descName }}</h1>
                <p>{{ hnItem.descList[1].descName }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-scrollbar"></div>
      </div>
      <div class="btn-div" :class="activeIndex===3?'home_last':''">
        <div class="btn-con" v-if="this.$store.state.pageset.pcshow">
          <el-button
            size="small"
            class="btn-div-order"
            v-if="activeIndex != 0"
            plain
            @click="retention"
            >Order Now</el-button
          >
          <el-button
            size="small"
            class="btn-div-experience"
            plain
            @click="openDetails"
            >Learn more</el-button
          >
        </div>
        <div class="btn-con" v-else>
          <button
            size="small"
            class="btn-div-experience"
            plain
            @click="openDetails"
          >
            Learn more
          </button>
          <button
            size="small"
            class="btn-div-order"
            v-if="activeIndex != 0"
            @click="retention"
          >
          Order Now
          </button>
        </div>
      </div>
    </div>
    <div v-if="activeIndex===3" class="footer_home">
        <Footer v-if="$store.state.pageset.zh" />
        <FooterEN v-else />
      </div>
  </div>
</template>

<script>
import content from "./content_en.js";

export default content;
</script>
<style scoped lang="scss">
@import "content_en.scss";

/deep/.swiper-slide video {
  pointer-events: auto !important;
}
@media all and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
  /* 百度浏览器特定样式 */
  video {
    margin: 0;
    padding: 0;
    border: none;
    width: 100%;
  }
}
</style>
