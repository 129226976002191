import PageMain from "@/components/pageMain/PageMain.vue";
import {
    getWebControlMaterialArea,
    addWebControlMaterialArea,
    editWebControlMaterialArea,
    deleteWebControlMaterialArea,
    getElementList,//获取元素
    addElement,// 新增元素
    editElement,//修改元素
    deleteElementById,//删除元素
} from "@/api/dataSet/materialManagement";
import {
    treeDataTranslate, //放置成树型结构
} from "@/utils/common"; //枚举数字返回文字
import { uploadUrl } from "@/utils/constant.js";
export default {
    components: {
        PageMain,
    },
    props: {
        meteIsSelection: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            tablePartData: [],
            tablePartLoading: false,
            tableElementData: [],
            tableElementLoading: false,
            selectElmentId: null,
            areaPar: {
                areaName: '',
                language:'',
            },
            elmentPar: {
                recordId: '',
                elementName: '',
            },
            dialogPart: {
                visible: false,
                title: "",
            },
            dialogElement: {
                visible: false,
                title: "",
            },
            partForm: {
                areaId: null,
                areaName: "",
                parentId: null,
                remark: "",
                createBy: '',
                createTime: "",
            }, //分区
            elmentForm: {
                elementId: null,
                elementName: '',//
                elements: [],//	元素集合
                type: null,
                createBy: '',
                remark: '',
                size: null,//大小
                recordId: null,//分区id
            }, //元素
            partRules: {
                areaName: [
                    {
                        required: true,
                        message: "请输入分区名",
                        trigger: "blur",
                    },
                ],
            },
            uploadUrl,
            uploadHeaders: {},
            cilckPartRow: {},
            selectElementId: null,//在元素选择的时候，所选元素的id
            elmentBigVisiable: false,
        };
    },
    watch: {
        "$route.query.lang": {
            handler: function (val, oldVal) {
                this.getWebControlMaterialArea();
            },
        }
    },
    mounted() {
        this.getWebControlMaterialArea();
        this.uploadHeaders = { Authorization: this.$store.state.user.token };
    },
    methods: {
        //获取分区
        async getWebControlMaterialArea() {
            this.tablePartLoading = true;
            this.areaPar.language=  this.$route.query.lang;
            let res = await getWebControlMaterialArea(this.areaPar);
            if (res.code === 200) {
                this.tablePartData = treeDataTranslate(res.data, "areaId");
                this.tablePartLoading = false;
                if (res.data.length) {
                    this.cilckPartRow = res.data[0];
                    this.elmentPar.recordId = res.data[0].areaId;
                    this.getElementList();
                }
            } else {
                this.tablePartLoading = false;
            }
        },

        //获取元素
        async getElementList() {
            this.tableElementLoading = true;
            let res = await getElementList(this.elmentPar);
            if (res.code === 200) {
                this.tableElementData = res.data;
                this.tableElementLoading = false;
            } else {
                this.tableElementLoading = false;
            }
        },

        // 点击分区
        handleNodeClick(row) {
            this.cilckPartRow = row;
            this.elmentPar.recordId = row.areaId;
            this.getElementList();
        },

        // 打开分区
        addPartition(row, eidtType) {
            this.dialogPart = {
                visible: true,
                titile:
                    eidtType === "add" || eidtType === "addChi"
                        ? "新增分区"
                        : "编辑【" + row.areaName + "】分区",
            };
            //新增分区
            this.dialogEditVisible = true;
            if (row && eidtType === "edit") {
                let copyObj = Object.assign({}, row);
                this.partForm = copyObj;
            } else if (eidtType === "add") {
                this.partForm = this.$options.data().partForm;
            } else {
                this.partForm = this.$options.data().partForm;
                this.partForm.parentId = row.areaId;
                this.partForm.createBy = this.$store.state.user.name;
            }
        },

        // 打开元素
        addElment(row, eidtType) {
            this.dialogElement = {
                visible: true,
                titile: eidtType === "add" ? "新增元素" : "编辑元素",
            };
            if (eidtType === "edit") {
                let elmentForm = Object.assign({}, row);
                this.elmentForm = elmentForm;
            } else {
                this.$data.elmentForm = this.$options.data.call(this).elmentForm;
            }
           this.elmentForm.createBy = this.$store.state.user.name;
        },

        // 上传成功
        async uploadSuccess(res, file) {
            let fileSize = (file.size / 1024 / 1024);
            if (fileSize > 50) {
                this.$message.error('文件大小超过50MB,请重新上传！');
                return;
            }
            if (res.code !== 200) {
                this.$message.error('上传失败，请稍后再试');
                return;
            }
            try {
                this.elmentForm.size = fileSize.toFixed(3);
                this.elmentForm.type = file.raw.type.split('/')[0];
                this.elmentForm.elements = [{
                    attName: res.data.name,
                    attUrl: res.data.url,
                }]
                if (this.$refs.video)
                    this.$refs.video.src = res.data.url;
            } catch (e) {
                this.$message.error('上传失败，请稍后再试');
            }
            this.$refs.avatarUpload.clearFiles();
        },

        //提交
        async submitFrom(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    let res = {};
                    // 提交分区
                    if (formName === "partForm") {
                        if (this.partForm.areaId) {
                            // 编辑
                            res = await editWebControlMaterialArea(this.partForm);
                        } else {
                            // 新增
                            res = await addWebControlMaterialArea(this.partForm);
                        }
                        if (res.code === 200) {
                            // this.$message({
                            //     type: "success",
                            //     message: `编辑成功!`,
                            // });
                            this.dialogPart.visible = false;
                            this.getWebControlMaterialArea();
                        }
                    }
                    else {
                        // 编辑
                        this.elmentForm.recordId = this.cilckPartRow.areaId;
                        if (this.elmentForm.elementId) {
                            res = await editElement(this.elmentForm);
                        } else {
                            //新增
                            res = await addElement(this.elmentForm);
                        }
                        if (res.code === 200) {
                            // this.$message({
                            //     type: "success",
                            //     message: `编辑成功!`,
                            // });
                            this.dialogElement.visible = false;
                            this.elmentPar.recordId = this.elmentForm.recordId;
                            this.getElementList();
                        }
                    }
                }
            });
        },

        async deleteElment(id, type) {
            if (type === "part") {
                //删除分区
                let res = await deleteWebControlMaterialArea(id);
                if (res.code === 200) {
                    this.$message({
                        type: "success",
                        message: `删除成功!`,
                    });
                    this.getWebControlMaterialArea();
                }
            } else {
                //删除元素
                let res = await deleteElementById(id);
                if (res.code === 200) {
                    this.$message({
                        type: "success",
                        message: `删除成功!`,
                    });
                    this.elmentPar.recordId = this.cilckPartRow.areaId;
                    this.getElementList();
                }
            }
        },

        // 下载
        downFile(row) {
            // window.location.href = row.elements[0].attUrl;
            const blob = new Blob([row.elements[0].attUrl], { type: 'application/vnd.ms-excel' });
            const a = document.createElement('a');
            const href = window.URL.createObjectURL(blob); // 创建下载连接
            a.href = href;
            a.download = decodeURI(row.elementName);
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a); // 下载完移除元素
            window.URL.revokeObjectURL(href); // 释放掉blob对象
            // const a = document.createElement('a');
            // a.style.display = 'none';
            // a.download = row.elementName;
            // a.href = row.elements[0].attUrl;
            // document.body.appendChild(a);
            // a.click(); 
            // document.body.removeChild(a);
        },
        radioChange() {
            this.$emit('selectEl', this.selectElementId)
        },

        openVedioPreview(row) {
            this.elmentBigVisiable = true;
            this.elmentForm = row;
        }
    },
};