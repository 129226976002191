<template>
  <div class="home">
        <HomeContent class="home_content"/>
  </div>
</template>
<script>
import home from "./home.js";

export default home;
</script>
<style scoped rel="stylesheet/stylus" lang=scss>
@import "~@/assets/style/common.scss";
@import "home.scss";

.home_content {
  width: 100%;
  height: calc(100vh - 50px);
  position: relative;
}
</style>