const getters = {
  // 登录板块
  userInfo: state => state.user.userInfo,
  token: state => state.user.token,
  oaToken: state => state.user.oaToken,
  name: state => state.user.name,
  deptName: state => state.user.deptName,


  bannerIndex: state => state.pageset.bannerIndex,
  activeIndex: state => state.pageset.activeIndex,//数据设置菜单栏索引
  changetitle: state => state.pageset.changetitle,//语言汉语名称
  change: state => state.pageset.change,//语言英语名称
  pcshow: state => state.pageset.pcshow,
  zh: state => state.pageset.zh,
  linkZhIndex: state => state.pageset.linkZhIndex,
  linkEnIndex: state => state.pageset.linkEnIndex,

   // 页面设置板块
  isSetting: state => state.pageset.isSetting,//设置状态
  sourceData: state => state.pageset.sourceData,//设置数据
  menuShow: state => state.pageset.menuShow,//菜单显示
};

export default getters;
