<template>
  <div class="scrollbar-wrapper">
    <div v-if="sourceData.elmentList">
      <div class="scrollbar-wrapper-main">
        <div>
          <p>元素设置存在差异，可折叠查看效果</p>
          <p></p>
        </div>
        <el-button
          size="mini"
          type="primary"
          @click="dialogHistroyVisible = true"
          >版本查看</el-button
        >
      </div>
      <el-collapse accordion v-model="activeNames" @change="handleChange">
        <el-collapse-item
          v-for="(item, index) in sourceData.elmentList"
          :key="index"
          :name="'elm' + item.id"
        >
          <template slot="title">
            <p>元素{{ index + 1 }}:</p>
          </template>
          <div class="elment-item">
            <div class="element-div">
              <span>PC端元素:</span>
              <div v-if="item.pcImg">
                <el-image
                  v-if="item.type === 'image'"
                  :src="item.pcImg"
                  :preview-src-list="[item.pcImg]"
                  style="width: 150px"
                >
                </el-image>
                <video
                  @click="openBigVisiable(item, 'pc')"
                  :ref="'video' + item.pcElementId"
                  v-else-if="item.type === 'video'"
                  autoplay="autoplay"
                  loop="loop"
                  style="width: 70px; height: 70px"
                  :src="item.pcImg"
                ></video>
                <span
                  class="other-element"
                  v-else-if="item.type === 'aplication' && item.pcImg"
                >
                  {{ item.pcImg ? item.pcImg : "无元素" }}
                </span>
       
              </div>
              <span class="other-element" v-else>
                  无元素
                </span>
              <el-button
                size="mini"
                type="primary"
                @click="repalceDia(item, 'pcImg')"
                >替换</el-button
              >
            </div>
            <div class="element-div">
              <span>手机端元素:</span>
              <div @click="openBigVisiable(item, 'phone')" v-if="item.phoneImg">
                <img
                  v-if="item.type === 'image' && item.phoneImg"
                  :src="item.phoneImg"
                  height="70px"
                  width="70px"
                />
                <video
                  :ref="'video' + item.phoneElementId"
                  :src="item.phoneImg"
                  v-else-if="item.type === 'video' && item.phoneImg"
                  autoplay="autoplay"
                  loop="loop"
                  style="width: 70px; height: 70px"
                ></video>
                <span
                  class="other-element"
                  v-else-if="item.type === 'aplication' && item.phoneImg"
                >
                  {{ item.phoneImg ? item.phoneImg : "无元素" }}
                </span>
             
              </div>
              <span class="other-element" v-else>
                  无元素
                </span>
              <el-button
                size="mini"
                type="primary"
                @click="repalceDia(item, 'phoneImg')"
                >替换</el-button
              >
            </div>
            <div
              v-for="(decItem, decIdex) in item.descList"
              :key="decIdex"
              class="element-dec"
            >
              描述字段{{ decIdex + 1 }}:
              <el-input
                type="textarea"
                :rows="2"
                placeholder="请输入内容"
                v-model="decItem.descName"
              >
              </el-input>
            </div>
          </div>
        </el-collapse-item>
      </el-collapse>
      <div class="scrollbar-wrapper-main">
        <span>备注：</span>
        <el-input
          type="textarea"
          style="width: 80%"
          size="mini"
          :rows="2"
          placeholder="请输入更改备注"
          v-model="sourceData.remark"
        >
        </el-input>
      </div>
      <div class="scrollbar-wrapper-main">
        <span>更改人：</span>
        <el-input
          type="text"
          style="width: 80%"
          size="mini"
          placeholder="请输入更改人"
          v-model="sourceData.updateBy"
        >
        </el-input>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-popconfirm
          title="取消将不会保存当前更改，确认取消吗？"
          @confirm="cancelSetting"
        >
          <el-button size="mini" slot="reference">取 消</el-button>
        </el-popconfirm>
        <!-- <el-button size="mini" @click="cancelSetting">取 消</el-button> -->
        <el-button
          style="margin-left: 15px"
          size="mini"
          type="primary"
          :loading="btnLoading"
          @click="saveElmentSet"
          >保 存</el-button
        >
      </div>
    </div>
    <div v-else>
      <p style="font-size: 16px">当前页面暂未开放配置功能！</p>
    </div>
    <!-- 素材替换 -->
    <el-dialog
      title="素材替换"
      :visible.sync="dialogVisible"
      width="85%"
      top="3vh"
      @close="cancelSelect"
    >
      <span style="font-size: 10px; color: red">
        只能选择同类型的文件替换<i class="el-icon-warning"></i
      ></span>
      <MaterialSelection
        v-if="dialogVisible"
        :meteIsSelection="meteIsSelection"
        @selectEl="selectEl"
      />
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="selectElSubmit">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 历史版本 -->
    <Histroy
      v-if="dialogHistroyVisible"
      :dialogHistroyVisible="dialogHistroyVisible"
      @cancelHistroy="cancelHistroy"
      @submitSelect="submitSelect"
    />
    <!-- 大图预览 -->
    <el-dialog
      v-if="elmentBigVisiable"
      title="大图预览"
      :visible.sync="elmentBigVisiable"
      width="70%"
      top="3vh"
      @close="elmentBigVisiable = false"
    >
      <div class="big-element">
        <video autoplay="autoplay" loop="loop" ref="previewVideo"></video>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import MaterialSelection from "@/views/dataSet/materialManagement/index.vue"; //元素选择
import Histroy from "../Histroy/Histroy.vue"; //历史版本

import {
  addReplacement, //新增
  editReplacement, //修改
  getSubOldInfo, //获取页面替换功能附属详细信息-历史版本
} from "@/api/pageSet/pageSet.js"; //历史版本
export default {
  components: {
    MaterialSelection,
    Histroy,
  },
  data() {
    return {
      sourceData: [],
      dialogVisible: false,
      dialogHistroyVisible: false, //历史版本
      meteIsSelection: true, //是否为元素选择替换
      nowRepalceObj: {}, //当前元素
      selectElObj: {}, //替换元素对象
      repalceType: "",
      elmentBigVisiable: false,
      btnLoading: false,
      activeNames: "",
    };
  },
  watch: {
    // 监听设置数据源变化
    "$store.state.pageset.sourceData": {
      handler: function (newVal, oldVal) {
        if (newVal) {
          this.sourceData = newVal;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {},
  mounted() {
    this.sourceData = this.$store.state.pageset.sourceData;
    this.sourceData.updateBy = this.$store.state.user.name;
  },
  methods: {
    // 滚动到指定区域
    handleChange(activeNames) {
      this.$nextTick(() => {
        let target = document.getElementById(activeNames);
        if (target) {
          target.scrollIntoView({ behavior: "smooth" }); // 平滑滚动
          // target.style.border = "2px solid #c24343";
        }
      });
    },
    // 根据历史版本id获取信息
    async getSubOldInfo(hisId) {
      let res = await getSubOldInfo(hisId);
      if (res.code === 200) {
        this.$store.dispatch("pageset/getSourceData", res.data);
      }
    },

    // 保存设置
    async saveElmentSet() {
      this.$confirm(
        `保存后，会将当前页面信息生效于sevnce.com， 确认保存吗？`,
        "提示",
        {
          confirmButtonText: "确认",
          cancelButtonText: "取消",
          type: "info",
        }
      ).then(async () => {
        this.btnLoading = true;
        let par = this.sourceData;
        // 新增
        // let res = await addReplacement(par);
        // 修改
        let res = await editReplacement(par);
        if (res.code === 200) {
          this.$message.success("保存成功");
          this.btnLoading = false;
        } else {
          this.btnLoading = false;
        }
      });
    },

    cancelSetting() {
      // 取消设置
      location.reload();
    },

    repalceDia(val, type) {
      // 初始没有元素的数据不允许替换素材
      // if (type === "pcImg" && !val.pcImg) {
      //   this.$message.error("当前元素没有配置PC端素材，无法替换！");
      //   return;
      // }
      // if (type === "phoneImg" && !val.phoneImg) {
      //   this.$message.error("当前元素没有配置手机端端素材，无法替换！");
      //   return;
      // }
      this.dialogVisible = true;
      this.repalceType = type;
      this.nowRepalceObj = val;
    },

    cancelSelect() {
      this.dialogVisible = false;
    },

    cancelHistroy() {
      this.dialogHistroyVisible = false;
    },

    selectEl(val) {
      this.selectElObj = val;
    },

    submitSelect(hisId) {
      this.getSubOldInfo(hisId);
      this.cancelHistroy();
      this.$message.success("回显成功!");
    },

    //确定替换元素
    selectElSubmit() {
      try {
        if (this.nowRepalceObj.type != this.selectElObj.type) {
          this.$message.error("所选素材于当前元素类型不一致，请确认！");
          return;
        }
        if (this.repalceType === "pcImg") {
          if (this.nowRepalceObj.type === "video") {
            // 如果是视频,则通过refs方式更新src
            this.$refs["video" + this.nowRepalceObj.pcElementId].src =
              this.selectElObj.elements[0].attUrl;
          }
          this.nowRepalceObj.pcImg = this.selectElObj.elements[0].attUrl;
          this.nowRepalceObj.pcElementId = this.selectElObj.elementId;
        } else {
          if (this.nowRepalceObj.type === "video") {
            // 如果是视频,则通过refs方式更新src
            this.$refs["video" + this.nowRepalceObj.phoneElementId].src =
              this.selectElObj.elements[0].attUrl;
          }
          this.nowRepalceObj.phoneElementId = this.selectElObj.elementId;
          this.nowRepalceObj.phoneImg = this.selectElObj.elements[0].attUrl;
        }
        this.dialogVisible = false;
      } catch (e) {
        this.$message.error("替换失败！");
      }
    },

    // 预览大图
    openBigVisiable(obj, type) {
      this.nowRepalceObj = obj;
      this.elmentBigVisiable = true;
      setTimeout(() => {
        if (type === "pc") {
          if (this.$refs.previewVideo) {
            this.$refs.previewVideo.src = this.nowRepalceObj.pcImg;
          }
        } else {
          if (this.$refs.previewVideo) {
            this.$refs.previewVideo.src = this.nowRepalceObj.phoneImg;
          }
        }
      }, 100);
    },
  },
};
</script>

<style scoped lang="scss">
.scrollbar-wrapper {
  padding: 30px 5px;
  color: #352f2f;

  .scrollbar-wrapper-main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5px 0px;
  }
}

.elment-item {
  padding: 5px 3px;
  .element-dec {
    border-top: 1px solid #797878;
    margin-top: 3px;
    padding: 3px 3px;
  }
}

.element-div {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

/deep/.el-collapse-item__header {
  background-color: #e8e8e8;
  height: 35px;
}
/deep/.el-collapse-item__content {
  padding-bottom: 10px;
}

/deep/.dialog-footer {
  margin-top: 10px !important;
}

/deep/.el-dialog__body {
  padding: 0px 20px;
}

//设置元素选择的高度
/deep/.table-wrape {
  height: 450px !important;
}

.big-element {
  height: 600px;
  width: 800px;
  display: flex;
  justify-content: center;
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  video {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.other-element {
  width: 80%;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}
</style>
