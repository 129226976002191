

// 测试
// export const baseUrl = 'http://192.168.1.67:9090';
// export const uploadUrl = 'http://192.168.1.67:9090/file/upload';
// export const downloadUrl = 'http://192.168.1.67:9300/statics';
// export const oaBaseUrl = 'http://oa.7tyun.com:8082'

// prod
export const baseUrl = 'https://sevnce.com';
export const uploadUrl = 'https://sevnce.com/file/upload';
export const downloadUrl = 'https://sevnce.com:9300/statics';
export const oaBaseUrl = 'https://oa.7tyun.com:4443'