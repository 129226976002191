import CommonDialog from "@/components/dialog/dialog.vue"

import HomeContentEn from "../components/content/en/content_en.vue"

export default {
    name: 'Home',
    data() {
        return {
            
        }
    },
    mounted() {
    },
    components: {
        CommonDialog,
        HomeContentEn,

    },
}